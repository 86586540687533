import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';
import IconForward from '../../../audi-ui-components/icons/Forward';
import IconSelect from '../../../audi-ui-components/icons/Select';
import { validate } from '../../../lib/validation';
import { loginSchema } from '../schema';
import { request } from '../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../lib/gtm';

import {
  PATH_HOME,
  PATH_REGISTER,
  PATH_CONFIRM_EMAIL,
  PATH_FORGOT_PASS,
  PATH_RESET_PASS
} from '../../../constants';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: props.initialStep || 1,
      isSubmitting: false,
      submitAttempted: false,
      serverError: false,
      showGetCode: false,
      showNewCodeSent: false
    }
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true, submitAttempted: true, serverError: false});
    if (this.state.step === 1) {
      this.handleCheckEmail(values, formikBag);
    } else if (this.state.step === 2) {
      this.handleLogin(values, formikBag);
    } else if (this.state.step === 3) {
      this.handleMFALogin(values, formikBag);
    }
  }

  handleCheckEmail = (values, formikBag) => {
    // console.log("handleCheckEmail", values);
    request(
      `${process.env.RAZZLE_API}/2/account/validateEmail`,
      {
        method: 'POST',
        body: JSON.stringify(values),
      }
    ).then((res) => {
      // console.log(res);
      if (res === "CONFIRMED" || res === "READY") {
        // email exists, go to step 2
        this.setState({step: 2, isSubmitting: false});
      } else if (res === "UNCONFIRMED") {
        // email not confirmed
        this.props.goTo(PATH_CONFIRM_EMAIL, 1);
      } else if (res === "NOTFOUND") {
        // email doesn't exist, go to register step 1
        this.props.goTo(PATH_REGISTER, 1);
      } else if (res === "RESET_REQUIRED" || res === "FORCE_CHANGE_PASSWORD") {
        request(
          `${process.env.RAZZLE_API}/2/account/password/request`,
          {
            method: 'POST',
            body: JSON.stringify(values)
          }
        ).then(() => {
          this.props.goTo(PATH_RESET_PASS, 3);
        }).catch((error) => {
          this.handleServerError(error, formikBag);
        });
      } else {
        this.handleServerError(res, formikBag);
      }
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }

  handleLogin = (values, formikBag) => {
    request(
      `${process.env.RAZZLE_API}/2/account/token`,
      {
        method: 'POST',
        body: JSON.stringify(values),
      }
    ).then((authData) => {
      // console.log("authentication success");
      if (authData.hasMFA) {
        // console.log("user has MFA enabled, go to step 3");
        formikBag.setFieldValue("session", authData.session, false);
        this.setState({step: 3, isSubmitting: false});
        setTimeout(()=>{ this.setState({showGetCode: true}); }, 5000);
      } else {
        // console.log("no MFA, proceed with loading profile");
        this.props.loadProfile(
          this.props.onLoadProfile,
          (error) => {
            // this.handleServerError(error, formikBag);
            let msg = <>Sorry, we've been unable to retrieve your profile. Try again, or if this continues please contact our <Link to="/contact-us" className="aui-textlink">customer service team</Link></>
            this.setState({isSubmitting: false, serverError: msg});
          },
          authData
        );
        gtmPush("myAudi", "loginSuccess", values.email);
      }
    }).catch((error) => {
      // console.log("catch error");
      if (this.state.step !== 2) { this.setState({step: 2}); }
      this.handleServerError(error, formikBag);
    });
  }
  
  handleMFALogin = (values, formikBag) => {
    request(
      `${process.env.RAZZLE_API}/2/account/authorization_code`,
      {
        method: 'POST',
        body: JSON.stringify({code: values.code, session: values.session, username: values.email}),
      }
    ).then((authData) => {
      // load profile
      this.props.loadProfile(
        this.props.onLoadProfile,
        (error) => {
          // this.handleServerError(error, formikBag);
          let msg = <>Sorry, we've been unable to retrieve your profile. Try again, or if this continues please contact our <Link to="/contact-us" className="aui-textlink">customer service team</Link></>
          this.setState({isSubmitting: false, serverError: msg});
        },
        authData
      );
      gtmPush("myAudi", "loginSuccess", values.email);
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }

  handleServerError = (error, formikBag) => {
    console.log('handleServerError', error);
    if (error.status === 403) {
      // email not confirmed, go to register step 2
      this.props.goTo(PATH_REGISTER, 2);
    } else if (error.status === 404) {
      // email doesn't exist, go to register step 1
      this.props.goTo(PATH_REGISTER, 1);
    } else {
      // some other error
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message && error.body.message.indexOf("<html") === -1) {
        msg = error.body.message;
      }
      this.setState({isSubmitting: false, serverError: msg});
    }
  }

  validateForm = (values) => {
    var schema = {
      email: loginSchema['email']
    };
    if (this.state.step === 2) {
      schema['password'] = loginSchema['password'];
    }
    if (this.state.step === 3) {
      schema['code'] = {
        presence: {
          message: "Please enter your authentication code",
          allowEmpty: false
        }
      };
    }
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }

  render() {
    const { from } = this.props;
    const { step } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={this.props.initialValues}>
        {(formikBag) => (
          <Form>

            {this.state.isSubmitting && <LoadingOverlay />}

            <p className="aui-headline-4 mb-3"><b>Log in to your myAudi Australia account</b></p>
            <FormField name="email" label="Email" formikBag={formikBag} showErrors={this.state.submitAttempted} onChange={this.props.onChangeEmail} />

            {step === 1 && <div>
              <p className="mb-5" style={{textAlign: 'right', fontSize: '90%'}}>
                <Link to={{pathname: PATH_REGISTER, state: {from: from}}} className="aui-textlink">Need an account? Create one!</Link>
              </p>
              <div className="form-field submit">
                <Button label="Next" type="submit" buttonType="primary" icon={<IconForward small />} iconPosition="right" />
              </div>
            </div>}

            {step === 2 && <div>
              <FormField name="password" type="password" label="Password" formikBag={formikBag} showErrors={this.state.submitAttempted} />
              <div className="d-flex justify-content-end">
                <Button buttonType="text" label="Forgot your password?" onClick={() => { this.props.goTo(PATH_FORGOT_PASS, 1) }} />
              </div>
              <div className="form-field submit">
                <Button label="Login" type="submit" buttonType="primary" />
              </div>
            </div>}
            
            {step === 3 && <div>
              <p className="mb-3">An authentication code has been sent to your phone. Please enter the code below.</p>
              <FormField name="code" type="text" label="Code" formikBag={formikBag} showErrors={this.state.submitAttempted} />
              <div className="mb-5" style={{pointerEvents: this.state.showGetCode ? "all" : "none", opacity: this.state.showGetCode ? "1" : "0", transition: "opacity .5s"}}>
                {!this.state.showNewCodeSent && <Button variant="text" disabled={this.state.showNewCodeSent} onClick={() => {
                  this.handleLogin(formikBag.values, formikBag);
                  this.setState({showNewCodeSent: true});
                  setTimeout(()=>{ this.setState({showNewCodeSent: false}); }, 5000);
                }}>
                  Resend code
                </Button>}
                {this.state.showNewCodeSent && <span className="aui-button"><span className="aui-color-text-green"><IconSelect small style={{verticalAlign: "middle"}} /> Code sent</span></span>}
              </div>
              <div className="form-field submit">
                <Button label="Login" type="submit" buttonType="primary" />
              </div>
            </div>}

            {step === 'locked' && <div>
              <p className="mb-5">Your account has been locked due to too many failed login attempts. You can either wait 30 minutes to try again or use the Forgotten Password link below to reset your password</p>
              <Link to={{pathname: PATH_FORGOT_PASS, state: {from: from}}} onClick={() => {this.setState({step: 1});}} className="aui-textlink">Forgotten Password</Link>
            </div>}

            {this.state.serverError && <p className="server-error py-3">{this.state.serverError}</p>}
          </Form>
        )}
      </Formik>
    );
  }
}

LoginForm.propTypes = {
  onChangeEmail: PropTypes.func,
  initialValues: PropTypes.object,
  initialStep: PropTypes.number,
  goTo: PropTypes.func,
  loadProfile: PropTypes.func,
  onLoadProfile: PropTypes.func,
  from: PropTypes.any
}

LoginForm.defaultProps = {
  from: { pathname: PATH_HOME }
}

export default LoginForm;
